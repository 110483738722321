<!--  -->
<template>
  <div class="main_box">
    <div class="nav_menu">
      <div class="menu_left">
        <el-input
          placeholder="请输入用户名称"
          v-model="searchValue.userName"
        ></el-input>
        <el-date-picker
          :editable="false"
          style="margin-left: -10px"
          v-model="searchValue.searchTime"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          prefix-icon="el-icon-time"
          clear-icon=""
          :picker-options="pickerOptions"
        >
        </el-date-picker>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button type="info" @click="handleReset">重置</el-button>
      </div>
      <div class="menu_right">
        <el-button type="success" @click="handleExport">导出</el-button>
      </div>
    </div>
    <div>
      <el-table
        :header-cell-style="{
          'background-color': '#4073b6',
          color: '#FFFFFF'
        }"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="loading"
        element-loading-text="数据量过大，加载中..."
      >
        <el-table-column type="index" prop="sid" label="序号" width="95">
        </el-table-column>
        <el-table-column prop="createTime" label="操作时间"> </el-table-column>
        <el-table-column prop="userName" label="用户名称"> </el-table-column>
        <el-table-column prop="roleName" label="角色名称"> </el-table-column>
        <el-table-column prop="departmentName" label="所属部门">
        </el-table-column>
        <el-table-column prop="action" label="操作模块"> </el-table-column>
        <el-table-column prop="result" label="操作内容"> </el-table-column>
      </el-table>
      <el-pagination
        :hide-on-single-page="true"
        background
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
        @current-change="handleCurrent"
        :current-page="pagination.pageNum"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      tableData: [],
      pagination: {
        size: 10,
        pageNum: 1,
        total: 0
      },
      searchValue: {
        userName: "",
        searchTime: [],
        startTime: "",
        endTime: ""
      },
      loading: false
    };
  },
  computed: {},
  watch: {},
  methods: {
    getList() {
      let { pageNum, size } = this.pagination;
      this.loading = true;
      this.$get(`/rainLog/page/${pageNum}/${size}`).then(res => {
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
        this.loading = false;
      });
    },
    handleCurrent(page) {
      let { endTime, startTime, userName } = this.searchValue;
      this.loading = true;
      this.$get(`/rainLog/page/${page}/${10}`, { userName }).then(res => {
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
        this.loading = false;
      });
    },
    handleSearch() {
      let { endTime, startTime, userName } = this.searchValue;
      let obj = {};
      if (this.searchValue.searchTime.length) {
        obj.startTime = this.searchValue.searchTime[0];
        obj.endTime = this.searchValue.searchTime[1];
        this.time = obj;
      } else {
        this.time = {
          startTime: "",
          endTime: ""
        };
      }
      userName != "" ? (obj.userName = userName) : "";
      this.$get(`/rainLog/page/${1}/${10}`, obj).then(res => {
        // console.log(res);
        this.tableData = res.data.list;
        this.pagination.total = res.data.total;
      });
    },
    handleReset() {
      this.$set(this.searchValue, "userName", "");
      this.$set(this.searchValue, "searchTime", "");
      this.getList();
    },
    handleExport() {
      if (this.searchValue.searchTime.length == 0) {
        fetch(
          this.$axios.defaults.baseURL +
            `/rainLog/export?userName=${this.searchValue.userName}`,
          {
            headers: {
              token: localStorage.getItem("token")
            }
          }
        )
          .then(res => {
            return res.blob();
          })
          .catch(err => {
            // console.log(err);
          })
          .then(e => {
            // console.log(e);
            const a = document.createElement("a");
            a.href = URL.createObjectURL(e);
            a.download = "操作日志表.xlsx";
            a.click();
          });
      } else {
        fetch(
          this.$axios.defaults.baseURL +
            `/rainLog/export?userName=${this.searchValue.userName}&startTime=${this.searchValue.searchTime[0]}&endTime=${this.searchValue.searchTime[1]}`,
          {
            headers: {
              token: localStorage.getItem("token")
            }
          }
        )
          .then(res => {
            return res.blob();
          })
          .catch(err => {
            // console.log(err);
          })
          .then(e => {
            // console.log(e);
            const a = document.createElement("a");
            a.href = URL.createObjectURL(e);
            a.download = "操作日志表.xlsx";
            a.click();
          });
      }
    }
  },
  created() {
    this.getList();
  },
  mounted() {}
};
</script>
<style lang='scss' scoped>
::v-deep .menu_left {
  .el-range-input {
    background: #f3f4f8 !important;
  }
  .el-range-separator {
    background: #f3f4f8 !important;
  }
  .el-input__icon {
    right: calc(0 / 1920 * 100vw) !important;
    top: calc(0 / 1080 * 100vh);
  }
}
</style>
